var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center mb-4",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.guardian-desc")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy"
    }],
    staticClass: "mb-1",
    attrs: {
      "content": "Jika ada benefisiari bawah umur 18 tahun atau OKU",
      "height": "20",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-end"
  }, [_c('div', {
    staticClass: "col-lg-12 d-flex justify-content-end"
  }, [[0, 1].includes(_vm.amendment_status) ? _c('div', {
    staticClass: "d-flex flex-column mb-4"
  }, [_vm.caretaker_array.length < 4 ? _c('b-button', {
    staticClass: "btn-sm btn-primary",
    on: {
      "click": _vm.tambahPenjagaModal
    }
  }, [_vm._v(_vm._s(_vm.$t("add-primary-caretaker")))]) : _vm._e()], 1) : _vm._e()])]), _c('div', {
    staticClass: "row d-flex mt-2 mb-3"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-hover table-sm"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "500px"
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), [0, 1, 4, 5].includes(_vm.amendment_status) ? _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")]) : _vm._e()])]), _c('tbody', [_vm._l(_vm.caretaker_array, function (caretaker, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(caretaker.name))]), [0, 1, 4, 5].includes(_vm.amendment_status) ? _c('td', {
      staticClass: "text-right"
    }, [_c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [[0, 1, 4, 5].includes(_vm.amendment_status) ? _c('b-button', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "tag": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.updateCaretaker(caretaker, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]) : _vm._e(), [0, 1, 4].includes(_vm.amendment_status) ? _c('b-button', {
      staticClass: "btn btn-danger btn-sm",
      attrs: {
        "tag": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteCaretaker(caretaker.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]) : _vm._e()], 1)]) : _vm._e()]);
  }), _vm.caretaker_array && _vm.caretaker_array.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_c('h6', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(_vm._s(_vm.$t("no-data")))])])]) : _vm._e()], 2)])])])])])])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [[0, 1].includes(_vm.amendment_status) ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'pindaan.part_f',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: this.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("save-next")))]) : _vm._e()], 1)])])])]), _c('b-modal', {
    ref: "modalTambahPenjaga",
    attrs: {
      "title": _vm.$t('amendment.keyguardian-info'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel'),
      "no-enforce-focus": true
    },
    on: {
      "ok": _vm.hideModal,
      "cancel": _vm.hideModalCaretaker
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.name.$model,
      expression: "$v.form.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "name"
    },
    domProps: {
      "value": _vm.$v.form.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.name.$error && !_vm.$v.form.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row d-flex justify-content-star"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.relationship.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("relationship")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.relationship.$model,
      expression: "$v.form.relationship.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "relationship",
      "id": "relationship"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.relationship, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changeRelationship($event);
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.relationship")) + " --")]), _vm._l(_vm.relationships, function (relationship) {
    return _c('option', {
      key: relationship,
      domProps: {
        "value": relationship
      }
    }, [_vm._v(" " + _vm._s(relationship) + " ")]);
  })], 2), _vm.$v.form.relationship.$error && !_vm.$v.form.relationship.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("relationship")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.ic_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "mask": ['######-##-####'],
      "id": "ic_number"
    },
    model: {
      value: _vm.$v.form.ic_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.ic_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.form.ic_number.$model"
    }
  }), _vm.$v.form.ic_number.$error && !_vm.$v.form.ic_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("old-ic")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.old_ic_number,
      expression: "form.old_ic_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "old_ic_number"
    },
    domProps: {
      "value": _vm.form.old_ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "old_ic_number", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.mobile_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.mobile_number.$model,
      expression: "$v.form.mobile_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "mobile_number"
    },
    domProps: {
      "value": _vm.$v.form.mobile_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.mobile_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.mobile_number.$error && !_vm.$v.form.mobile_number.numeric ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-in-number")) + " ")]) : _vm._e(), _vm.$v.form.mobile_number.$error && !_vm.$v.form.mobile_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-no")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("home-telno")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.home_phone_number,
      expression: "form.home_phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "home_phone_number"
    },
    domProps: {
      "value": _vm.form.home_phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "home_phone_number", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mailing-address")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.sameAddress,
      expression: "form.sameAddress"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": "customCheck1",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.sameAddress) ? _vm._i(_vm.form.sameAddress, "1") > -1 : _vm.form.sameAddress
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.form.sameAddress,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "sameAddress", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "sameAddress", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "sameAddress", $$c);
        }
      }, _vm.copyAddress]
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "customCheck1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.same-address")) + " ")])]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.address.$model,
      expression: "$v.form.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "readonly": _vm.form.sameAddress == 1,
      "id": "address",
      "rows": "2"
    },
    domProps: {
      "value": _vm.$v.form.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.address.$error && !_vm.$v.form.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.postcode.$error
    }
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.postcode,
      expression: "postcode",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode",
      "readonly": _vm.form.sameAddress == 1
    },
    domProps: {
      "value": _vm.postcode
    },
    on: {
      "change": function ($event) {
        _vm.postcode = $event.target.value.trim();
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.postcode.$error && !_vm.$v.form.postcode.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.postcode")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "city",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "state",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.mykad.frontUrl.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykadcopy-front"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.mykad.frontUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.frontUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-1"
    }
  }) : _vm._e(), !_vm.mykad.frontUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("choose.mykadfront")))])]) : _vm._e(), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-1",
      "accept": "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'mykad-front');
      }
    }
  })])]), _vm.$v.mykad.frontUrl.$error && !_vm.$v.mykad.frontUrl.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("document")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm.mykad.frontUrl ? _c('b-button', {
    staticClass: "btn-sm btn-success m-1",
    on: {
      "click": function ($event) {
        return _vm.openIcFront('front');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.mykad.backUrl.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykadcopy-back"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.mykad.backUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.backUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e(), !_vm.mykad.backUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("choose.mykadback")))])]) : _vm._e(), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-2",
      "accept": "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'mykad-back');
      }
    }
  })])]), _vm.$v.mykad.backUrl.$error && !_vm.$v.mykad.backUrl.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("document")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm.mykad.backUrl ? _c('b-button', {
    staticClass: "btn-sm btn-success m-1",
    on: {
      "click": function ($event) {
        return _vm.openIcFront('back');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")]) : _vm._e()], 1)])])])])]), _c('b-modal', {
    ref: "modalPostcode",
    staticClass: "modal fade",
    attrs: {
      "no-enforce-focus": true,
      "ok-title": "Simpan",
      "cancel-title": "Batal"
    },
    on: {
      "ok": _vm.savePostcode
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(_vm._s(_vm.$t("add-postcode")))])]), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.postcode,
      expression: "postcode",
      modifiers: {
        "lazy": true
      }
    }],
    staticClass: "form-control savePostcode",
    attrs: {
      "type": "number",
      "name": "userstate"
    },
    domProps: {
      "value": _vm.postcode
    },
    on: {
      "change": function ($event) {
        _vm.postcode = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.post.city,
      expression: "post.city"
    }],
    staticClass: "form-control saveCity",
    attrs: {
      "type": "text",
      "name": "userstate"
    },
    domProps: {
      "value": _vm.post.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.post, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.post.state,
      expression: "post.state"
    }],
    staticClass: "form-control saveState",
    attrs: {
      "type": "text",
      "name": "userstate"
    },
    domProps: {
      "value": _vm.post.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.post, "state", $event.target.value);
      }
    }
  })])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }